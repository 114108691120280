import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import Container from "react-bootstrap/Container";
import esLocale from "@fullcalendar/core/locales/es";
import { NavbarFix } from "../../Components";

import "./styles.css";

const NuevaPagina = () => {
  return (
    <div className="container--calendar">
      <NavbarFix />
      <Container>
        <div>
          <h1 className="title--calendar">Calendario de actividades</h1>
        </div>
        <div className="container--full-calendar">
          <FullCalendar
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            locales={[esLocale]}
            events={[
              {
                title: "Inicio de semestre I", 
                start: "2025-02-08",
                end: "2025-02-08",
              },
              {
                title: "Entrega de proyectos",
                start: "2025-06-14",
                end: "2025-06-14",
              },
              {
                title: "Finalización de semestre",
                start: "2025-06-07",
                end: "2025-06-07",
              },
              {
                title: "Inscripciones semestre II",
                start: "2025-06-28",
                end: "2025-06-28",
              },
              {
                title: "Feria de educación Edutechnia - Bogotá",
                start: "2025-08-27",
                end: "2025-08-30",
              },
              {
                title: "Inicio Semestre II",
                start: "2025-07-26",
                end: "2025-07-26",
              },
              {
                title: "Torneo de Robótica - Edubotica",
                start: "2025-03-29",
                end: "2025-03-29",
              },
            ]}
          />
        </div>
      </Container>
      <footer className="footer">
        <p className="mb-0">MI SUPERPODER ES LA PROGRAMACIÓN - ©2022</p>
        <p className="mb-0">Medellín, Colombia</p>
      </footer>
    </div>
  );
};

export default NuevaPagina;
