import React from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Carousel from 'react-bootstrap/Carousel';
import { Slider, NavbarFix, Cards, Modules, Goals } from "../../Components";
import logomision from "../../assets/images/logo-mision.webp";
import logoWrite from "../../assets/images/flori.webp";
import clouds from "../../assets/images/bg.webp";
import signo from "../../assets/images/logo_signo.webp";
import logoFC from "../../assets/images/logo-fc.webp";
import logoINS from "../../assets/images/logo-insta.webp";
import logoIN from "../../assets/images/logo-in.webp";
import sello from "../../assets/images/sello_ganador.png";
import koralat from "../../assets/images/logo_koralat.png";
import sequence from "../../assets/images/secuences.png";
import flowchart from "../../assets/images/flow_chart.png";
import computer from "../../assets/images/computer_parts.png";
import arduino from "../../assets/images/arduino.png";
import "./styles.css";

const Home = () => {
  return (
    <div className="App">
      <NavbarFix />
      <div className="container-slider">
        <Slider />
        <img
          src={clouds}
          alt=""
          className="clouds"
          width={"auto"}
          height={"auto"}
        />
      </div>
      <div>
        <div className="welcome-container">
          <div className="welcome-video">
            <video
              className="video_home"
              controls
              controlsList="nodownload"
              poster="https://imgsmisuperpoder.s3.us-east-2.amazonaws.com/fondo_kick.png"
            >
              <source
                src="https://d368521dinrqoa.cloudfront.net/msp_video.mp4"
                type="video/mp4"
              />
            </video>
          </div>
          <div>
            <h2 className="title">Bienvenidos</h2>
            <p className="paragraph welcome-text">
              Este hermoso proyecto nació en el año 2017 con el objetivo de llegar
              a las poblaciones más vulnerables de la ciudad de Cali. Nuestra meta
              estaba clara, compartir con los niños y jóvenes nuestros
              conocimientos en programación. Gracias al apoyo de diferentes
              entidades, comunidades e instituciones educativas hemos logrado
              tocar puertas para llegar a muchos más niños en todo el país.
              A día de hoy, contamos con nuestra propia herramienta, con la cual es
              posible enseñar todos los conceptos de informática y computación,
              antes de pasar a la codificación. Juntos lograremos llegar a todos los
              rincones de Colombia para transformar vidas a través de muchas líneas
              de código.
            </p>
          </div>
        </div>
        <img src={sello} alt="" width={"300"} height={"195"} className="img-prize" />
        <p className="text-prize"><b>MEJOR APP EDUCATIVA 2024</b></p>
        <div className="custom-shape-divider-top-1719448039">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
          </svg>
        </div>
        <div className="container-cards">
          <Cards />
        </div>
        <div className="custom-shape-divider-top-1719448039">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="color-violet"></path>
          </svg>
        </div>
        <div className="container-mission">
          <div>
            <img src={logomision} alt="" width={"290"} height={"300"} />
          </div>
          <div>
            <h2 className="title">Nuestra Misión</h2>
            <p className="paragraph welcome-text">
              Transformar por medio de la tecnología, brindando herramientas a
              los niños y jóvenes para incursionar en la programación, además de
              desarrollar habilidades y capacidades lógicas y creativas para la
              solución de problemáticas no solo de tipo informático sino también
              de día a día.
            </p>
          </div>
        </div>
        <div className="container-modules">
          <h2 className="title tc">Nuestra Herramienta</h2>
          <Container>
            <Row>
              <Col sm={6} className="center-container">
                <p className="paragraph">
                  Nuestra plataforma ha sido diseñada para apoyar procesos de
                  aprendizaje en programación y computación, iniciando por los fundamentos
                  antes de escribir líneas de código. En la herramienta podrás
                  encontrar ejercicios de patrones, secuencias, diagramas de flujo
                  y pseudocódigo. Además los profesores contarán con recursos y planeaciones
                  académicas que fortalecerán el proceso de enseñanza.
                  ¡Juntos por una enseñanza de la programación de manera divertida y creativa!
                </p>
              </Col>
              <Col sm={6}>
                <Carousel slide={false}>
                  <Carousel.Item className="carousel-item_tool">
                    <img src={computer} alt="" className="carousel_tool" />
                  </Carousel.Item>
                  <Carousel.Item className="carousel-item_tool">
                    <img src={sequence} alt="" className="carousel_tool" />
                  </Carousel.Item>
                  <Carousel.Item className="carousel-item_tool">
                    <img src={flowchart} alt="" className="carousel_tool" />
                  </Carousel.Item>
                  <Carousel.Item className="carousel-item_tool">
                    <img src={arduino} alt="" className="carousel_tool" />
                  </Carousel.Item>
                </Carousel>
              </Col>
            </Row>
          </Container>
        </div>
        <img
          src={clouds}
          alt=""
          className="clouds-down"
          width={"auto"}
          height={"auto"}
        />
        <div className="container-cards">
          <h2 className="title tc">Nuestros Talleres</h2>
          <p className="paragraph tc mb-20">
            Nuestros talleres van desde los inicios de la computación e
            informática, hasta conceptos avanzados que les permite a los niños y
            jóvenes relacionarse con lenguajes de programación reales
          </p>
          <div>
            <Modules />
          </div>
        </div>
        <div className="custom-shape-divider-top-1719448039">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="color-violet"></path>
          </svg>
        </div>
        <div className="container-goals">
          <Goals />
        </div>
        <div className="container-class">
          <h2 className="title tc">IR A MIS CLASES</h2>
          <img src={signo} alt="" width={"150"} height={"150"} />
          <p>
            Ahora todo es mucho más fácil. Accede a tus clases sin irte a otro
            lugar o plataforma. Ayuda a Mayito a superar todos los desafíos y
            conviértete en un súper programador.
          </p>
          <p>
            <b>¡Aprender a programar nunca fue tan fácil y divertido!</b>
          </p>
          <a
            href="https://clases.misuperpodereslaprogramacion.com/"
            target="_blank"
            rel="noreferrer"
          >
            <Button className="btn-goclass" variant="primary">
              ¡IR AHORA!
            </Button>
          </a>
        </div>
        <img
          src={clouds}
          alt=""
          className="clouds-down"
          width={"auto"}
          height={"auto"}
        />
      </div>
      <div className="container-supp">
        <h2 className="title tc mb-4">Nuestros Aliados</h2>
        <p>
          Conoce las empresas e instituciones educativas que nos respaldan y apoyan
        </p>
        <img
          src={koralat}
          alt=""
          className="w100"
          width={450}
          height={"auto"}
        />
      </div>
      <div className="custom-shape-divider-top-1719448039">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="color-violet"></path>
        </svg>
      </div>
      <div className="container-writing">
        <h2 className="title tc">Escríbenos</h2>
        <p>
          Envía un mensaje al correo info@misuperpodereslaprogramacion.com o al
          whastapp +57 318 738 1607 y pronto te responderemos.
        </p>
        <p>Nuestros horarios de atención:</p>
        <p>7:00 am – 6:00 pm</p>
        <img src={logoWrite} alt="" width={"400"} height={"auto"} />
      </div>
      <div className="container-divider"></div>
      <div className="container-info">
        <Container>
          <Row>
            <Col sm={6}>
              <div className="paragraph tc">
                <p>
                  <b>Información de contacto:</b>
                </p>
                <p className="mb-2">
                  <b>Celular:</b> +57 318 738 1607
                </p>
                <p>
                  <b>Correo:</b> info@misuperpodereslaprogramacion.com
                </p>
              </div>
            </Col>
            <Col sm={6}>
              <div>
                <p className="paragraph tc">
                  <b>Redes Sociales:</b>
                </p>
                <div className="container-img-network">
                  <a href="https://www.facebook.com/misuperpodereslaprogramacion/">
                    <img src={logoFC} alt="" width={"35"} height={"35"} />
                  </a>
                  <a href="https://www.instagram.com/misuperpodereslaprogramacion/">
                    <img src={logoINS} alt="" width={"au35to"} height={"35"} />
                  </a>
                  <a href="https://www.linkedin.com/company/mi-superpoder-es-la-programaci%C3%B3n/">
                    <img src={logoIN} alt="" width={"35"} height={"35"} />
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <footer className="footer">
        <p className="mb-0">MI SUPERPODER ES LA PROGRAMACIÓN - ©2022</p>
        <p className="mb-0">Medellín, Colombia</p>
      </footer>
    </div>
  );
};

export default Home;
